import React,{useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Grid,
    TextField,
    Typography,
    Input,
    Select,
    MenuItem,
    LinearProgress,
} from "@material-ui/core";
import { useForm, Controller } from 'react-hook-form';
import plusIcon from '@material-ui/icons/Add';

import { addNewCLIADirector } from '../../../httpCalls/fetchLocations';
import {
    toggleNotification,
    notificationMessage
} from "actions/TestActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isNullOrUndefined } from 'helperFunctions';
import TypeAhead from "./unameTypeAhead";
import {  searchLdap,getAllUsers } from 'http_calls/userHttp'

const useStyles = makeStyles(theme => ({
    TextField: {
        minWidth: '25vw',
    },
    progressRoot: {
        flexGrow: 1
    }
}));



function setKeyToImmutable(obj,key,value){
    obj[key] =value
    return obj
}
function getDefaultUserState(){
    return {
        uname: '',
        first_name: '',
        last_name: '',
        role: {
            "name": "lab_tech",
            "labs": [],
            "finalize_request_change": false,
            "request_change": false,
            "view_all_lab_tests": false,
            "synonym_ops": false,
            "major_request_change": false,
            "edit_page_layout": false,
            "clia_approval": false
        },
        drafts: {},
        searchs: {},
        app: ""
    }
}


function AddNewCLIADirector(props) {

    let lastTriggeredTime;
    const preFetchUsers = [];
    const [open, setOpen] = useState(false);
    const [cliaDirector, setCliaDirector] = useState("");
    const [location, setLocation] = useState("");
    const [unameSearchs, setUnameSearchs] = useState([]);
    const [showLoading,setShowLoading] = useState(false);
    const [user, setUser] = useState(getDefaultUserState());
    
    const [errors,setErrors] = useState({
        location:false,
        cliaDirector:false,
    });

    useEffect(()=>{
        
        getAllUsers().then(res => {
            if (res.data.msg.IsError === false) {
                preFetchUsers.push(...res.data.msg.Data);
                setUnameSearchs([...res.data.msg.Data]);
            }
        }).catch(err => console.error(err))

    },[])
    const classes = useStyles()

    const handleClose = () => {
        setOpen(false);
    }
    const handleOpen = () => {
        setOpen(true);

    }
    const handleUnameChange = (e) => {
        if (e.target){
        let value = e.target.value
        let _user = { ...user }
        _user.uname = value
        setUser(_user)
       searchLDAP(value)
        if (value.length > 3) {
            // setShowLoading(true)
            // let _currentTime = new Date()
            // if (_currentTime-lastTriggeredTime > 5000 || isNullOrUndefined(lastTriggeredTime) ){
            //     searchLDAP(value)
            // }
           setUnameSearchs(preFetchUsers.filter(user => {return user.Uname.includes(value) || user.FirstName.includes(value) || user.LastName.includes(value)})) 
        }else{
            setShowLoading(false);
            setUnameSearchs(... preFetchUsers);
        }
        }
    }
    lastTriggeredTime = new Date()
    
    const handleOnUnameSelect = (selectedItem)=>{
        setErrors(setKeyToImmutable({...errors}, "uname", false))
        let _user = { ...user }
        _user.uname = selectedItem.Uname
        _user.first_name=selectedItem.FirstName
        _user.last_name= selectedItem.LastName
        console.log( "selected username is ", selectedItem)
        setUser(_user)
        setUnameSearchs([])
        setShowLoading(false)
    }
    const closeProgress =()=>{
        setShowLoading(false)
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let _obj ={
            clia_director : user.uname,
            location,
        }
        let _errors = {...errors}
        if(_obj.clia_director.length < 6 ){
        _errors.cliaDirector  = true;
        return;
        }
        if(_obj.location.length < 1) {
            _errors.location = true;
            return;
        }
        console.log("submitting ", _obj );

        addNewCLIADirector(_obj).then(res => {
            props.toggleNotification(true);
            props.notificationMessage(res);
            props.refreshData();
            setOpen(false);
        }).catch(err => console.error(err))
    }




    return (
        <div>
            <Button id="add-new-clia-director" variant="contained" size="medium" color="primary" onClick={handleOpen}>
                <plusIcon /> Add New CLIA Director
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                
            {showLoading? <LinearProgress/>:<span/>}
                <form method="post" onSubmit={handleSubmit} >
                    <DialogTitle>
                        Add New CLIA Director
                    </DialogTitle>
                    <DialogContent>
                        <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="center"
                            spacing={2}>
                            <Grid item xs={12}>
                                {/* <TextField
                                    id="outlined-clia-director"
                                    item
                                    label="clia director"
                                    type="text"
                                    name="clia_director"
                                    value={cliaDirector}
                                    onChange={e=>{setCliaDirector(e.target.value)}}
                                    className={classes.TextField}
                                    variant="outlined"
                                    helperText=
                                    "Please enter CLIA Director's uname."
                                    error={errors.clia_director}
                                /> */}
                                <TypeAhead uname={user.uname} 
                                    handleUnameChange={handleUnameChange} 
                                    suggestions={unameSearchs} 
                                    handleOnUnameSelect={handleOnUnameSelect} 
                                    stopProgress={closeProgress}
                                    error={errors.uname}
                                    />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    id="outlined-Clia-location"
                                    item
                                    label="clia location"
                                    type="text"
                                    name="location"
                                    value={location}
                                    onChange={e=>{setLocation(e.target.value)}}
                                    className={classes.TextField}
                                    variant="outlined"
                                    helperText=
                                    "Please enter Location"
                                    error={errors.location}
                                />

                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" type="submit">Save</Button>
                        <Button color="secondary" onClick={handleClose}>  Cancel </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    )
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { toggleNotification, notificationMessage },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewCLIADirector);