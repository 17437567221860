import React,{useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Card, CardContent, CardHeader, Avatar, Grid, IconButton } from '@material-ui/core';
import Alert from './index_components/AlertDialog';
import {deleteCLIALocation} from '../../httpCalls/fetchLocations';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 325,
    height: 325,
  },
  avatar: {
    backgroundColor: theme.palette.primary,
  },
  content: {
    height: 160,
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  actions: {
    height: 50,
  }

}));

export default function CLIADirectorCard(props){
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [deleteClia, setDeleteClia] = useState("");
  const [deleteLocation, setDeleteLocation] = useState("");

  const removeCLIA = (location, clia_director) => {
    deleteCLIALocation({location, clia_director}).then(res=> {
      setOpen(false);
      props.refreshData();
      console.log(res);
    }).catch(err=> {
      console.error(err);
    })
  }

  const onClickDelete = (clia, location) => {
    setDeleteClia(clia);
    setDeleteLocation(location);
    setOpen(true);
  }

  return (
    <Grid item>
      <Card className={classes.root}>
          <CardHeader title = {props.CLIA_director+" : "+ props.full_name}  className={classes.header}>
            <Avatar aria-label={props.CLIA_director} className={classes.avatar}>

            </Avatar>
          </CardHeader>
          <CardContent className={classes.content}>
              <List>
              {props.locations.map(loc=>(
                  <ListItem key={loc} >
                    <ListItemText primary={loc} />
                    <ListItemSecondaryAction>
                      <IconButton color='secondary' edge="end" aria-label="delete" onClick={()=>onClickDelete(props.CLIA_director, loc)}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
              ))}
              </List>

          </CardContent>
          {/* <CardActions className={classes.actions}>
              <Button variant='contained' color='primary' size='small'>Update CLIA Directors</Button>
              <Button variant ='contained' color='secondary' size='small'>Remove CLIA Directors</Button>
          </CardActions> */}
     </Card>


     <Alert open={open} 
        handleClose={()=>setOpen(false)} 
        handleAgree={()=>{removeCLIA(deleteLocation, deleteClia)}} 
        title={'Remove CLIA Director'} 
        content={`Are you sure you want to remove ${deleteClia} CLIA Director from ${deleteLocation} location?`} 
        agreeBtnText={'Remove'} 
        disagreeBtnText={'Cancel'} 
        agreeBtnColor={'secondary'} 
        disagreeBtnColor={'primary'}/>
    </Grid>
     


  );
}